import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { useMemo } from 'react'

import AssetIcon from 'components/assets/shared/AssetIcon'
import useFetchSimplifiedAssets from 'js/assets/hooks/useFetchSimplifiedAssets'
import useDimensions from 'js/utils/hooks/useDimensions'
import { isUk } from 'src/constants'

import 'components/pages/web3-wallet/scss/AssetsSection.scss'

function getAssetObjects(assets, selectedList) {
  if (
    !(
      Array.isArray(assets) &&
      assets.length > 0 &&
      Array.isArray(selectedList) &&
      selectedList.length > 0
    )
  ) {
    return []
  }

  const assetsTickerMap = new Map()
  assets.forEach((asset) => assetsTickerMap.set(asset.ticker, asset))

  return selectedList.map((ticker) => assetsTickerMap.get(ticker)).filter(Boolean)
}

const itemsPerRow = [5, 4, 5]
const assetList = [
  'BNB',
  'ADA',
  'ALGO',
  'FTM',
  'AVAX',

  'SOL',
  'BTC',
  'ETH',
  'MATIC',

  'ARB',
  'OP',
  'RBTC',
  'LINK',
]

const AssetIcons = ({ isMobile, assets, grid }) => {
  const Wrapper = ({
    link,
    title,
    className = 'x__web3-wallet-page__assets__asset-icon',
    children,
  }) =>
    !isUk && link ? (
      <Link className={className} href={`/${link}`} title={title}>
        {children}
      </Link>
    ) : (
      <span className={className}>{children}</span>
    )

  let startIndex = 0
  const rows = grid.map((rowCount) => {
    const endIndex = startIndex + rowCount
    const assetsSlice = assets.slice(startIndex, endIndex)

    let rowItems = assetsSlice.map(({ icon, name, ticker, link }, i) => (
      <Wrapper key={`${name}-${ticker}-${i}`} link={link} title={`${name} (${ticker})`}>
        <AssetIcon icon={icon} name={name} ticker={ticker} size="100" />
      </Wrapper>
    ))

    if (rowCount > assetsSlice.length) {
      const emptyCount = rowCount - assetsSlice.length
      const emptyArray = Array.from({ length: emptyCount }, (_, i) => i + 1)
      rowItems = [
        ...rowItems,
        emptyArray.map((item) => (
          <Wrapper key={`more-${item}`} title="More assets coming soon">
            <AssetIcon icon={null} alt="More assets coming soon" size="100" />
          </Wrapper>
        )),
      ]
    }
    startIndex = endIndex

    return rowItems
  })

  return (
    <div className="x__web3-wallet-page__assets__wrapper">
      {rows.map((content, i) => {
        const speed = i === 0 || i === rows.length - 1 ? (isMobile ? '0.1' : '0.8') : 0

        return (
          <div
            key={i}
            className={classNames('x__web3-wallet-page__assets__row', {
              'x__web3-wallet-page__assets__row--short': content.length < 5,
              'web3-wallet-page-rellax': speed,
            })}
            data-rellax-speed={speed}
          >
            {content}
          </div>
        )
      })}
    </div>
  )
}

export default function AssetsSection({ data }) {
  const { assets } = useFetchSimplifiedAssets()
  const { isMobile } = useDimensions()

  const selectedAssets = useMemo(() => {
    return getAssetObjects(assets, assetList)
  }, [assets, assetList])

  return (
    <section className="x__web3-wallet-page__assets">
      <AssetIcons assets={selectedAssets} grid={itemsPerRow} isMobile={isMobile} />

      {data.h2 ? (
        <div className="x__web3-wallet-page__assets__content">
          <h2 className="x__web3-wallet-page__assets__heading">{data.h2}</h2>
        </div>
      ) : null}
    </section>
  )
}
